import type { MotaponTypes } from "@/types/MotaponTypes";
import type { MTP_TEXTURE_IDS } from "@/utils/TextureManager";
import type { Composer, VueI18n } from "vue-i18n";
import type { Router } from "vue-router";

/**
* Created : 23/09/2022 
* This class only exists to solve the circular imports hell
*/
export default class StoreProxy {
	
	public static main:IMainState & IMainGetters & IMainActions & {$state:IMainState, $reset:()=>void};
	public static auth:IAuthState & IAuthGetters & IAuthActions & {$state:IAuthState, $reset:()=>void};
	public static game:IGameState & IGameGetters & IGameActions & {$state:IGameState, $reset:()=>void};
	public static i18n:VueI18n<{}, {}, {}, string, never, string, Composer<{}, {}, {}, string, never, string>>;
	public static router:Router;
	public static asset:(path: string) => string;
	
}

export interface IMainState {
	/**
	 * Get if app initialize completed
	 */
	initComplete:boolean;
	/**
	 * Latest Twitchat update index
	 */
	latestUpdateIndex: number;
	/**
	 * If set, a confirmation window is opened
	 */
	confirmData:MotaponTypes.ConfirmData|null;
	/**
	 * Current alert data (user alert() to populate)
	 */
	alertData: {
		/**
		 * Message to display
		 */
		message:string;
		/**
		 * defines if it's a critical error. It will remain on screen and won't be replacable
		 */
		critical:boolean;
		/**
		 * Show contact button (discord link)
		 */
		showContact:boolean;
	};
}

export interface IMainGetters {
}

export interface IMainActions {
	/**
	 * Starts the app
	 * @param authenticate whether we want to authenticate (ex:chat) or not (ex:home)
	 * @param callback 
	 */
	startApp(authenticate:boolean, callback:(value:unknown)=>void):Promise<void>;
	/**
	 * Opens up an alert at the top of the app on red bar
	 * @param message 
	 * @param isCritical defines if it's a critical error. It will remain on screen and won't be replacable
	 * @param showContact defines if contact info should be displayed (discord link)
	 */
	alert(message:string, isCritical?:boolean, showContact?:boolean):void;
	/**
	 * Opens up a confirm window requesting the user to confirm or cancel
	 * @param title 
	 * @param description 
	 * @param data 
	 * @param yesLabel 
	 * @param noLabel 
	 * @param STTOrigin is open from speech recognition ? If so, voice commands are displayed
	 */
	confirm<T>(title: string, description?: string, data?: T, yesLabel?:string, noLabel?:string, STTOrigin?:boolean): Promise<T|undefined>;
	/**
	 * Close confirm window
	 */
	closeConfirm():void;
}


export type RequireField<T, K extends keyof T> = T & Required<Pick<T, K>>
export type IAuthState = {
	/**
	 * Is user authenticated
	 */
	authenticated: boolean;
}

export interface IAuthGetters {
}

export interface IAuthActions {
}


export type IGameState = {
	/**
	 * Current level index (starts at 0)
	 */
	currentLevel:number;
	/**
	 * Letter slots available
	 */
	letterList: MotaponTypes.LetterData[];
	/**
	 * Selected letter slots
	 * Used to know in which order selected letters are
	 */
	currentWord: MotaponTypes.LetterData[];
	/**
	 * Current map level
	 */
	map:MotaponTypes.MapCell[][];
	/**
	 * Is current word valid?
	 */
	wordValid:boolean;
	/**
	 * Defines if debugging the game (ctrl+d to toggle)
	 * allows to place any word anywhere
	 */
	debugMode:boolean;
	/**
	 * Current word's direction
	 */
	wordDirection:MotaponTypes.WordDirection;
	/**
	 * Where the character should move to
	 */
	characterPath:number[][];
	/**
	 * Contains the name of a freshly captured fish
	 */
	capturedFishes:{new:boolean, id:MotaponTypes.FilterKeys<MTP_TEXTURE_IDS, "fish">}[];
	/**
	 * Freezes the UI to avoid placing words while walking
	 */
	freezeUI:boolean;
}

export interface IGameGetters {
}

export interface IGameActions {
	/**
	 * Start a new level
	 * @param index 
	 */
	startLevel(level:number):Promise<void>
	/**
	 * Create a new letter
	 * @param letter 
	 */
	createLetter(letter:string):void;
	/**
	 * Selects a letter from the letters pool
	 * @param letter 
	 */
	selectLetter(letter?:MotaponTypes.LetterData, char?:string):boolean;
	/**
	 * Unselects a letter
	 * @param letter 
	 */
	unselectLetter(letter:MotaponTypes.LetterData):boolean;
	/**
	 * Clears all selected letters
	 */
	clearSelectedLetters():void;
	/**
	 * Check if the word an be used at the specifed coordinates
	 * @param x 
	 * @param y 
	 */
	canUseWord(x:number, y:number):boolean;
	/**
	 * Place current word at the given coordinates
	 */
	placeWord(x:number, y:number):boolean;
	/**
	 * Resets letters states
	 */
	resetLetters():void;
	/**
	 * Updates current word valid state
	 */
	updateWordState():void;
	/**
	 * Discards currently selected letters
	 */
	discardCurrent():void;
	/**
	 * Adds a random letter
	 */
	addLetter():void;
	/**
	 * Automatically moves the character to the nearest object/platform avaiable
	 */
	moveCharacter():void;
	/**
	 * Gets the path from start to current character path
	 */
	getStartToCharacterPath():number[][];
	/**
	 * Captures a random fish
	 */
	captureFish():void;
	/**
	 * Clears freshly captured fish list
	 */
	clearCapturedFish():void;
	/**
	 * Freezes the UI to avoid placing words while walking
	 */
	freeze():void;
	/**
	 * Unfreezes the UI to allow placing words again
	 */
	unfreeze():void;
}