import Utils from "./Utils";

/**
* Created : 14/02/2024 
*/
export default class DictionnaryManager {

	private static _instance:DictionnaryManager;

	private _wordList:string[] = [];
	private _wordMap:{[key:string]:boolean} = {};
	private _longest:string = "";
	
	constructor() {
	
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	static get instance():DictionnaryManager {
		if(!DictionnaryManager._instance) {
			DictionnaryManager._instance = new DictionnaryManager();
		}
		return DictionnaryManager._instance;
	}

	public get longestWordSize():number { return this._longest.length; }
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	/**
	 * Load the given dictionnary language
	 * @param lang 
	 */
	public async loadLang(lang:string):Promise<void> {
		const res = await fetch("dictionnary/"+lang+".txt");
		if(res.status == 200) {
			const txt = await res.text();
			this._wordList = []
			this._wordMap = {};
			const words = txt.split("\r\n");
			words.push("MOTAPON");
			await this.initBatch(words);
		}
	}

	/**
	 * Check if the given word exists on the current dictionnary
	 * @param word 
	 * @returns 
	 */
	public wordExists(word:string):boolean {
		return this._wordMap[this.sanitizeWord(word)] === true;
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
	private sanitizeWord(word:string):string {
		return word.toLowerCase().trim();
	}
	
	/**
	 * Initializes a batch of words
	 * @param words 
	 */
	private async initBatch(words:string[], offset:number=0):Promise<void> {
		let count = 0;
		while(offset+count < words.length && count < 50000) {
			const word = this.sanitizeWord(words[offset+count]);
			this._wordList.push(word);
			this._wordMap[word] = true;
			if(word.length > this._longest.length) {
				this._longest = word;
				// console.log(word);
			}
			count ++;
		}
		if(offset+count < words.length) {
			await Utils.promisedTimeout(100);
			await this.initBatch(words, offset + count);
		}
	}
}