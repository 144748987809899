<template>
	<div :class="classes">
		<router-view />
		<Confirm />
		<Alert />
		<div class="noise"></div>
	</div>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Component, Vue, toNative } from 'vue-facing-decorator';
import Alert from "./views/AlertView.vue";
import Confirm from "./views/Confirm.vue";


@Component({
	components:{
		Alert,
		Confirm,
	}
})
class App extends Vue {

	public showContent:boolean = false;

	public get classes():string[] {
		let res = ["app"];
		return res;
	}

	public mounted():void {
		watch(()=> this.$store.main.initComplete, ()=> this.hideMainLoader())
		this.hideMainLoader();
	}
	
	public beforeUnmount():void {
		
	}

	private hideMainLoader():void {
		if(this.$store.main.initComplete === true) {
			window.closeInitLoader();//Method declared on index.html
		}
	}
}
export default toNative(App);
</script>

<style scoped lang="less">
.app{
	width: 100%;
	height: var(--vh);
	overflow: hidden;

	.noise {
		background-image: url(@/assets/img/noise_pattern.png);
		z-index: 99999;
		top: 0;
		left: 0;
		pointer-events: none;
		opacity: .03;
		width: 100vw;
		height: 100vh;
		position: fixed;
		mix-blend-mode: multiply;
	}
}
</style>