import { defineStore, type _StoreWithState, type _StoreWithGetters, type PiniaCustomProperties } from "pinia";
import type { IAuthActions, IAuthGetters, IAuthState } from "../StoreProxy";
import type { UnwrapRef } from "vue";


export const storeAuth = defineStore('auth', {
	state: () => ({
		authenticated: false,
	} as IAuthState),
	
	
	
	getters: {
	},
	
	
	
	actions: {

	} as IAuthActions
	& ThisType<IAuthActions
		& UnwrapRef<IAuthState>
		& _StoreWithState<"auth", IAuthState, IAuthGetters, IAuthActions>
		& _StoreWithGetters<IAuthGetters>
		& PiniaCustomProperties
	>,
})