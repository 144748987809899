<template>
	<component :is="to? 'router-link' : 'button'" :to="disabled !== false? {} : to" :class="classes" @click="onClick()">
		<slot v-if="!loading"></slot>
		<img v-if="loading" src="/loader.svg" alt="loader">
	</component>
</template>

<script lang="ts">
import gsap from 'gsap';
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component({
	components:{},
	emits:[],
})
export default class MButton extends Vue {

	@Prop({default:false, type:Boolean})
	public disabled!:boolean;

	@Prop({default:false, type:Boolean})
	public loading!:boolean;

	@Prop()
	public to!:{name:string};

	public get classes():string[] {
		const res:string[] = ["mbutton"];
		if(this.disabled !== false || this.loading) res.push("disabled");
		return res;
	}

	public onClick():void {
		if(this.disabled !== false) return;

		gsap.fromTo(this.$el, {scaleY:.5}, {scaleY:1, ease:"elastic.out(1.5)", duration:.5})
	}

}
</script>

<style scoped lang="less">
.mbutton{
	font-family: inherit;
	font-size: 1.5rem;
	background-color: var(--color-primary);
	border: none;
	padding: .5em;
	border-radius: .5em;
	font-weight: bold;
	position: relative;
	cursor: pointer;
	border-bottom: 3px solid rgba(0, 0, 0, .5);
	transition: background-color .25s, padding .2s, margin .2s;
	transform-origin: bottom center;
	text-decoration: none;
	color: var(--color-text);
	text-align: center;

	&:hover {
		background-color: var(--color-primary-light);
	}

	&.disabled {
		background-color: var(--color-primary-light);
		filter: saturate(20%);
		cursor: not-allowed;
		opacity: .75;
	}

	&::before {
		content: "";
		position: absolute;
		top: 3px;
		right: 4px;
		width: 5px;
		height: 5px;
		border-right: 3px solid #060b00;
		border-top: 3px solid #060b00;
		border-radius: 4px;
		transform: rotate(-45deg);
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 3px;
		left: 10px;
		width: 5px;
		height: 5px;
		border-left: 4px solid #060b00;
		border-bottom: 3px solid #060b00;
		border-radius: 4px;
		transform: rotate(145deg);
	}

	&:active:not(.disabled) {
		margin-top: .5em;
		padding: .25em .5em;
	}
}
</style>