import Database from "@/db/Database";
import type { MotaponTypes } from "@/types/MotaponTypes";
import DictionnaryManager from "@/utils/DictionnaryManager";
import LevelManager from "@/utils/LevelManager";
import type { MTP_TEXTURE_IDS } from "@/utils/TextureManager";
import TextureManager from "@/utils/TextureManager";
import Utils from "@/utils/Utils";
import { defineStore, type PiniaCustomProperties, type _StoreWithGetters, type _StoreWithState } from "pinia";
import type { UnwrapRef } from "vue";
import type { IGameActions, IGameGetters, IGameState } from "../StoreProxy";

export const storeGame = defineStore('game', {
	state: () => ({
		currentLevel:0,
		map:[[]],
		letterList: [],
		currentWord: [],
		freezeUI:false,
		wordValid:false,
		debugMode:false,
		wordDirection:"h",
		characterPath:[],
		capturedFishes:[],
	} as IGameState),
	
	
	
	getters: {
	},
	
	
	
	actions: {

		async startLevel(level:number):Promise<void> {
			await DictionnaryManager.instance.loadLang("fr");
			
			this.currentLevel = level;
			this.clearSelectedLetters();
			this.letterList = [];
			for (let i = 0; i < 10; i++) {
				this.addLetter();
			}

			// this.createLetter("p")
			// this.createLetter("a")
			// this.createLetter("l")
			// this.createLetter("m")
			// this.createLetter("e")

			const levelW = level+25;
			const levelH = 10;
			this.map = await LevelManager.instance.buildLevel(level, levelW, levelH);
		
			for (let y = 0; y < this.map.length; y++) {
				for (let x = 0; x < this.map[y].length; x++) {
					const cell = this.map[y][x];
					if(cell.sprite == "bridge/start") {
						this.characterPath = [[x,y]]
					}
				}
			}
		},

		createLetter(letter:string):void {
			this.letterList.push({
				id:Utils.getUUID(),
				letter,
				selected:false,
				error:false,
				disabled:false,
			})
		},

		selectLetter(letter:MotaponTypes.LetterData | undefined, char?:string):boolean {
			if(this.currentWord.length >= DictionnaryManager.instance.longestWordSize) return false;
			if(!letter) {
				//Create a fake letter
				letter = {
					id:Utils.getUUID(),
					error:true,
					letter:char||"",
					selected:false,
					disabled:true,
				}
			}
			if(!letter) return false;
			if(letter.selected) return false;
			letter.selected = true;
			this.currentWord.push(letter);
			this.updateWordState();
			return true;
		},

		unselectLetter(letter:MotaponTypes.LetterData):void {
			letter.selected = false;
			const index = this.currentWord.findIndex(v=>v.id == letter.id);
			this.currentWord.splice(index, 1);
			this.updateWordState();
		},

		clearSelectedLetters():void {
			this.currentWord = [];
			this.letterList.forEach(letter => letter.selected = false)
			this.updateWordState();
		},

		canUseWord(x:number, y:number):boolean {
			const verbose = false;
			const word = this.currentWord;

			const allowedToPlaceLetterOn:typeof this.map[number][number]["sprite"][] = ["fish", "empty"];
			
			const len = this.wordDirection == "h"? x + word.length : y + word.length;
			const offset = this.wordDirection == "h"? x : y;
			let isError = false;
			let allWordsValid = true;
			let isNextToValidCell = this.debugMode;
			let mapClone:typeof this.map = JSON.parse(JSON.stringify(this.map));
			word.forEach(v=>v.error = false);

			if(this.wordDirection == "h") {
				if(y > this.map.length-1) return false;
				if(y < 0) return false;
			}else{
				if(x > this.map[0].length-1) return false;
				if(x < 0) return false;
			}

			const hasLetterAround = (x:number, y:number):boolean => {
				const top		= y > 0? this.map[y-1][x] : undefined;
				const bottom	= y < this.map.length-1? this.map[y+1][x] : undefined;
				const left		= x > 0? this.map[y][x-1] : undefined;
				const right		= x < this.map[y].length-1? this.map[y][x+1] : undefined;
				return top?.sprite == "letter"
					|| bottom?.sprite == "letter"
					|| left?.sprite == "letter"
					|| right?.sprite == "letter";
			}

			let i = this.wordDirection == "h"? x : y;
			let index = 0;
			for (; i < len; i++, index++) {
				let letter = word[i - offset];
				//Letter outside on top or left, flag as error
				if(i < 0) {
					letter.error = true;
					isError = true;
					if(verbose) console.log("Letter outside board", letter.letter);
					continue;
				}

				//Word overflowing on right or bottom, flag all overflowing letters as errors
				let array = this.wordDirection == "h"? this.map[0] : this.map;
				if(i > array.length-1) {
					isError = true;
					for (let j = i-offset; j < word.length; j++) {
						word[j].error = true;
						if(verbose) console.log("Letter outside board", letter.letter);
					}
					break;
				}
				
				//Check if letter can be placed here
				let inPlace:MotaponTypes.MapCell|undefined = this.wordDirection == "h"? this.map[y][i] : this.map[i][x];
				let isIdenticalLetter = inPlace.sprite == "letter" && inPlace.letterData.letter.toLowerCase() == letter.letter.toLowerCase() && !inPlace.drown;
				//If current letter isn't disabled but the same is already placed on the board
				//check if there is another identical disabled letter on the current word and
				//switch place with it
				if(isIdenticalLetter && !letter.disabled) {
					const identicalDisabledIndex = word.findIndex(v=> v.letter.toLowerCase() === letter.letter.toLowerCase() && v.disabled);
					if(identicalDisabledIndex > -1) {
						let tmp = word[identicalDisabledIndex];
						word[identicalDisabledIndex] = letter;
						word[i-offset] = tmp;
						this.currentWord = word;
						letter = tmp;
						isError = false;
					}
				}
				const inPlace_validLetter	= !inPlace
											|| allowedToPlaceLetterOn.includes(inPlace.sprite)
											|| isIdenticalLetter;
				if(!inPlace_validLetter) {
					letter.error = true;
					isError = true;
					if(verbose) console.log("Invalid letter in-place", letter.letter);
				}
				
				//If there is no letter in the current slot but the letter we try to place
				//is disabled, do not allow placement unless debug mode enabled
				if(!this.debugMode) {
					if((!inPlace || inPlace.sprite != "letter") && letter.disabled) {
						isError = true;
						if(verbose) console.log("Letter is disabled", letter.letter);
					}
				}

				//Check if word is next to another letter, the start or a bridge
				let top:MotaponTypes.MapCell|undefined = undefined;
				let right:MotaponTypes.MapCell|undefined = undefined;
				let bottom:MotaponTypes.MapCell|undefined = undefined;
				let left:MotaponTypes.MapCell|undefined = undefined;
				let topCoords = {x:0, y:0};
				let rightCoords = {x:0, y:0};
				let bottomCoords = {x:0, y:0};
				let leftCoords = {x:0, y:0};
				if(this.wordDirection == "h") {
					if(y-1 >= 0) {
						top = this.map[y-1][i];
						topCoords.x = i;
						topCoords.y = y-1;
					}
					if(this.map[y][i+1]) {
						right = this.map[y][i+1];
						rightCoords.x = i+1;
						rightCoords.y = y;
					}
					if(y+1 < this.map.length) {
						bottom = this.map[y+1][i];
						bottomCoords.x = i;
						bottomCoords.y = y+1;
					}
					if(this.map[y][i-1]) {
						left = this.map[y][i-1];
						leftCoords.x = i-1;
						leftCoords.y = y;
					}
				}else{
					if(i-1 >= 0) {
						top = this.map[i-1][x];
						topCoords.x = x;
						topCoords.y = i-1;
					}
					if(this.map[i][x+1]) {
						right = this.map[i][x+1];
						rightCoords.x = x+1;
						rightCoords.y = i;
					}
					if(i+1 < this.map.length) {
						bottom = this.map[i+1][x];
						bottomCoords.x = x;
						bottomCoords.y = i+1;
					}
					if(this.map[i][x-1]) {
						left = this.map[i][x-1];
						leftCoords.x = x-1;
						leftCoords.y = i;
					}
				}
				if(!isNextToValidCell) {
					//Check if next to start bridge or an existing letter
					isNextToValidCell	  = (top?.sprite == "bridge/start" && word.length > 1) || top?.sprite == "start" || top?.sprite == "letter";
					isNextToValidCell	||= right?.sprite == "letter";
					isNextToValidCell	||= (bottom?.sprite == "bridge/start" && word.length > 1) || bottom?.sprite == "letter";
					isNextToValidCell	||= (left?.sprite == "bridge/start" && word.length > 1) || left?.sprite == "letter";
					
					//Check if next to a inbetween bridge and that bridge has a letter next to it
					if(word.length > 1) {
						isNextToValidCell	||= top?.sprite == "bridge/bridge"		&& hasLetterAround(topCoords.x, topCoords.y);
						isNextToValidCell	||= right?.sprite == "bridge/bridge"	&& hasLetterAround(rightCoords.x, rightCoords.y);
						isNextToValidCell	||= bottom?.sprite == "bridge/bridge"	&& hasLetterAround(bottomCoords.x, bottomCoords.y);
						isNextToValidCell	||= left?.sprite == "bridge/bridge"		&& hasLetterAround(leftCoords.x, leftCoords.y);
					}
				}
			}

			//Make sure all words on grid are valid
			if(!isError && !this.debugMode) {
				//Place word on temporary matrix
				if(this.wordDirection == "h"){
					const length = this.currentWord.length + x;
					for (let i = 0; x < length; x++, i++) {
						const sprite = mapClone[y][x].sprite;
						if(!allowedToPlaceLetterOn.includes(sprite)) continue;
						mapClone[y][x] = {
							sprite:"letter",
							drown:false,
							letterData:this.currentWord[i],
						};
					}
				}else{
					const length = this.currentWord.length + y;
					for (let i = 0; y < length; y++, i++) {
						const sprite = mapClone[y][x].sprite;
						if(!allowedToPlaceLetterOn.includes(sprite)) continue;
						mapClone[y][x] = {
							sprite:"letter",
							drown:false,
							letterData:this.currentWord[i],
						};
					}
				}

				const words = [];
				//Get all horizontal words
				for (let _y = 0; _y < mapClone.length; _y++) {
					const row = mapClone[_y];
					let localWord = "";
					for (let _x = 0; _x < row.length; _x++) {
						const cell = mapClone[_y][_x];
						if(cell.sprite == "letter") {
							localWord += cell.letterData.letter;
						}else{
							if(localWord.length > 1) words.push(localWord);
							localWord = "";
						}
					}
					if(localWord.length > 1) words.push(localWord);
				}
				//Get all vertical words
				for (let _x = 0; _x < mapClone[0].length; _x++) {
					let localWord = "";
					for (let _y = 0; _y < mapClone.length; _y++) {
						const cell = mapClone[_y][_x];
						if(cell.sprite == "letter") {
							localWord += cell.letterData.letter;
						}else{
							if(localWord.length > 1) words.push(localWord);
							localWord = "";
						}
					}
					if(localWord.length > 1) words.push(localWord);
				}

				words.forEach(word => {
					if(allWordsValid && !DictionnaryManager.instance.wordExists(word.toLowerCase())) {
						allWordsValid = false;
					}
				})
			}

			if(verbose && !isNextToValidCell) console.log("Word not next ot valid cell");
			return !isError && allWordsValid && isNextToValidCell;
		},

		placeWord(x:number, y:number):boolean {
			//Make sure the word can be placed
			if(!this.canUseWord(x,y)) return false;

			if(this.wordDirection == "h"){
				const length = this.currentWord.length + x;
				for (let i = 0; x < length; x++, i++) {
					const currentCell = this.map[y][x];
					if(currentCell.sprite == "fish") {
						this.captureFish();
					}
					if(currentCell.sprite == "letter" && currentCell.letterData) {
						this.currentWord[i].selected = false;//Keep letter
					}else{
						this.map[y][x] = {
							sprite:"letter",
							drown:false,
							letterData:this.currentWord[i],
						};
					}
				}
			}else{
				const length = this.currentWord.length + y;
				for (let i = 0; y < length; y++, i++) {
					const currentCell = this.map[y][x];
					if(currentCell.sprite == "fish") {
						this.captureFish();
					}
					if(currentCell.sprite == "letter" && currentCell.letterData) {
						this.currentWord[i].selected = false;//Keep letter
					}else{
						this.map[y][x] = {
							sprite:"letter",
							drown:false,
							letterData:this.currentWord[i],
						};
					}
				}
			}
			for (let i = 0; i < this.letterList.length; i++) {
				const letter = this.letterList[i];
				if(!letter.selected) continue;
				this.letterList.splice(i, 1);
				i--;
				this.addLetter();
			}
			this.currentWord = [];
			//Reset direction to horizontal after placing word
			this.wordDirection = "h";
			LevelManager.instance.updateMap(this.map);
			this.moveCharacter();
			return true;
		},
		
		resetLetters():void {
			this.currentWord.forEach(v=>v.error = false);
		},
		
		updateWordState():void {
			const word = this.currentWord.map(v=>v.letter).join("");
			this.wordValid = word.length > 1 && DictionnaryManager.instance.wordExists(word);
		},
		
		discardCurrent():void {
			const letters = this.currentWord.filter(v=> !v.disabled);
			for (let i = 0; i < letters.length; i++) {
				const index = this.letterList.findIndex(v => v.id == letters[i].id);
				this.letterList.splice(index, 1);
			}
			for (let i = 0; i < letters.length; i++) {
				this.addLetter();
			}
			this.currentWord = [];
		},

		addLetter():void {
			const probabilities = [12.1, 7.11, 6.59, 6.51, 6.39, 6.07, 5.92, 5.02, 4.96, 4.49, 3.67, 3.18, 2.62, 2.49, 1.23, 1.14, 1.11, 1.11, 1.11, 0.65, 0.46, 0.38, 0.34, 0.29, 0.17, 0.15];
			const letters = ['e', 'a', 'i', 's', 'n', 'r', 't', 'o', 'l', 'u', 'd', 'c', 'm', 'p', 'g', 'b', 'v', 'h', 'f', 'q', 'y', 'x', 'j', 'k', 'w', 'z'];
			
			//Reduce by 50% the chances for currently available letters to be picked again
			this.letterList.forEach(letter => {
				const index = letters.indexOf(letter.letter);
				probabilities[index] *= .5;
			});

			const vowelCount = this.letterList.filter(v=> {
				return /[aeiouy]/i.test(v.letter);
			}).length;
			
			let entries:{proba:number, value:string}[] = [];
			probabilities.forEach((p, index)=> {
				entries.push({proba:p, value:letters[index]})
			})


			//If there are less than 30% of vowels in the available letters
			//give 70% chances to get a vowel
			if(vowelCount < this.letterList.length * .30 && Math.random() < .70) {
				entries = entries.filter(l => {
					return /[aeiouy]/i.test(l.value);
				})
			}

			this.createLetter( Utils.pickRandProb(entries) );
		},

		moveCharacter():void {
			LevelManager.instance.updateMap(this.map, false, true);
			
			const targets:{x:number, y:number}[] = [];
			const objects:{x:number, y:number}[] = [];
		
			//Search for all objects and empty slots
			for (let y = 0; y < this.map.length; y++) {
				for (let x = 0; x < this.map[y].length; x++) {
					const cell = this.map[y][x];
					if(cell.sprite == "bridge/bridge") {
						if(cell.object) {
							objects.push({x, y});
						}else{
							targets.push({x, y});
						}
					}
				}
			}

			let selectedPath:number[][] = [];
			let minSize = Number.MAX_VALUE;
			const index = this.characterPath.length -1;
			const startPos = {x:this.characterPath[index][0], y:this.characterPath[index][1]};
			for (let i = 0; i < objects.length; i++) {
				const path = LevelManager.instance.getPath(startPos, objects[i]);
				if(path.length > 0 && path.length < minSize) {
					minSize = path.length;
					selectedPath = path;
					selectedPath.push([objects[i].x, objects[i].y]);
				}
			}
			
			if(selectedPath.length == 0) {
				selectedPath = [];
				let maxX = Number.MIN_VALUE;
				for (let i = 0; i < targets.length; i++) {
					const isCurrentPos = targets[i].x == startPos.x && targets[i].y == startPos.y;
					let path = LevelManager.instance.getPath(startPos, targets[i]);
					if(isCurrentPos) path = [[startPos.x, startPos.y]];
					const endPos = path[path.length-1];
					if(path.length > 0 && endPos[0] > maxX) {
						maxX = endPos[0];
						selectedPath = path;
						if(!isCurrentPos) {
							selectedPath.push([targets[i].x, targets[i].y]);
						}
					}
				}
			}
			
			if(selectedPath.length > 1) {
				this.characterPath = selectedPath;
			}

			//Make drown tiles walkable again
			LevelManager.instance.updateMap(this.map);
		},

		getStartToCharacterPath():number[][] {
			for (let y = 0; y < this.map.length; y++) {
				for (let x = 0; x < this.map[y].length; x++) {
					const cell = this.map[y][x];
					if(cell.sprite == "bridge/start") {
						const endpos = this.characterPath[this.characterPath.length-1];
						const path = LevelManager.instance.getPath({x, y}, {x:endpos[0], y:endpos[1]});
						return path;
					}
				}
			}
			return [];
		},

		captureFish():void {
			let fishes = Object.keys(TextureManager.instance.spritesheet.textures).filter(key => key.indexOf("fish/") == 0) as MotaponTypes.FilterKeys<MTP_TEXTURE_IDS, "fish">[];
			//Allow to win this fish only if 90% of all have been discovered
			if(Database.instance.capturedFishes.length < fishes.length*.9) {
				fishes = fishes.filter(f => f != "fish/lokiness");
			}

			// let fish = "fish/rififih";
			let fish = Utils.pickRand(fishes);
			let isNew = Database.instance.capturedFishes.findIndex(v => v.id === fish) > -1;

			Database.instance.captureFish(fish);

			this.capturedFishes.push({new:isNew, id:fish});
		},

		clearCapturedFish():void { this.capturedFishes = []; },

		freeze():void { this.freezeUI = true; },

		unfreeze():void { this.freezeUI = false; }

	} as IGameActions
	& ThisType<IGameActions
		& UnwrapRef<IGameState>
		& _StoreWithState<"auth", IGameState, IGameGetters, IGameActions>
		& _StoreWithGetters<IGameGetters>
		& PiniaCustomProperties
	>,
})