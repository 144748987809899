import type { MTP_TEXTURE_IDS } from "@/utils/TextureManager";

export namespace MotaponTypes {

	export type WordDirection = "h"|"v";

	/**
	 * Represents info about a confirm window
	 */
	export interface ConfirmData {
		title:string,
		description?:string,
		confirmCallback?:()=>void,
		cancelCallback?:()=>void,
		yesLabel?:string,
		noLabel?:string,
		STTOrigin?:boolean,
	}

	export interface LetterData {
		id:string;
		letter:string;
		selected:boolean;
		disabled:boolean;
		error:boolean;
	}

	type MapCellBase<S extends string = MTP_TEXTURE_IDS> = {
		sprite:S;
		flip?:boolean;
		/**
		 * Binary flag in the form
		 * T R B L
		 * Value 0010 allows to walk to the bottom
		 */
		directions?:DirectionBitFlag;
	}

	export type FilterKeys<T extends string, U extends string> = T extends `${U}/${infer R}` ? T : never;

	/**
	 * Default cell type
	 */
	export type MapEmpty = {
	} & MapCellBase<"empty">;

	/**
	 * Represents a locked cell (for big rocks, 1 cell is the rock the others are locked)
	 */
	export type MapLock = {
	} & MapCellBase<"lock">;

	/**
	 * Represents a letter cell
	 */
	export type MapCellLetter = {
		letterData: LetterData;
		drown: boolean;
	} & MapCellBase<"letter">;

	/**
	 * Represents a bridge slot with an object or chest on it
	 */
	export type MapInbetweeSlot = {
		object?: FilterKeys<MTP_TEXTURE_IDS, "objects">;
		drown: boolean;
	} & MapCellBase<"bridge/bridge">;

	/**
	 * Represents a fish
	 */
	export type MapFish = {
	} & MapCellBase<"fish">;

	/**
	 * Represents a rock
	 */
	export type MapRock = {
	} & MapCellBase<FilterKeys<MTP_TEXTURE_IDS, "rocks">>;

	/**
	 * Represents the start cells
	 * "start", is the first 2 bridges parts, "bridge/start" is the actual sprite
	 */
	export type MapStart = {
	} & MapCellBase<"start" | "bridge/start">;
	
	export type MapCell =  MapEmpty | MapLock | MapCellLetter | MapInbetweeSlot | MapFish | MapRock | MapStart;

	export type DirectionBitFlag = 0b0000 | 0b0001 | 0b0010 | 0b0011 |
							0b0100 | 0b0101 | 0b0110 | 0b0111 |
							0b1000 | 0b1001 | 0b1010 | 0b1011 |
							0b1100 | 0b1101 | 0b1110 | 0b1111;

	type test = MapCell["sprite"];

	export function CellTypeToDirections(type:MapCell["sprite"]):DirectionBitFlag {
		switch(type) {
			case "start": return 0b0111;
			case "bridge/start": return 0b1111;
			case "bridge/bridge": return 0b1111;
			case "empty": 
			case "letter": 
			case "fish":
				return 0b1111;
		}
		return 0b0000;
	}
}