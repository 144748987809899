import { defineStore, type PiniaCustomProperties, type _StoreWithGetters, type _StoreWithState } from 'pinia';
import type { UnwrapRef } from 'vue';
import type { IMainActions, IMainGetters, IMainState } from '../StoreProxy';
import TextureManager from '@/utils/TextureManager';
import FontsManager from '@/utils/FontsManager';
import StoreProxy from '../StoreProxy';
import DictionnaryManager from '@/utils/DictionnaryManager';

export const storeMain = defineStore("main", {
	state: () => ({
		latestUpdateIndex: 1,
		alertData:{
			message:"",
			critical:false,
			showContact:false,
		},
		initComplete: false,
		confirmData:null,
	} as IMainState),
	

	getters: {
	},

	
	actions: {

		async startApp(authenticate:boolean, callback:(value:unknown)=>void) {
			//TODO authenticate blablabla
			
			window.setInitMessage("chargement des polices");
			await FontsManager.instance.load();
			
			window.setInitMessage("chargement des textures");
			await TextureManager.instance.load();
			
			// window.setInitMessage("chargement du dictionnaire");
			// await DictionnaryManager.instance.loadLang("fr");
			
			// window.setInitMessage("building level");
			// await StoreProxy.game.startLevel(0);
			
			window.setInitMessage("chargement terminé");
			
			this.initComplete = true;
			
			callback(null);
		},

		alert(message:string, isCritical:boolean = false, showContact:boolean = false) {
			this.alertData.message = message;
			this.alertData.critical = isCritical;
			this.alertData.showContact = showContact;
		},

		confirm<T>(title: string, description?: string, data?: T, yesLabel?:string, noLabel?:string, STTOrigin?:boolean): Promise<T|undefined> {
			return <Promise<T|undefined>>new Promise((resolve, reject) => {
				this.confirmData = {
					title,
					description,
					yesLabel,
					noLabel,
					STTOrigin,
					confirmCallback : () => {
						resolve(data);
					},
					cancelCallback : () => {
						reject(data);
					}
				}
			});
		},

		closeConfirm():void { this.confirmData = null; },

	} as IMainActions & ThisType<IMainActions & UnwrapRef<IMainState> & _StoreWithState<"main", IMainState, IMainGetters, IMainActions> & _StoreWithGetters<IMainGetters> & PiniaCustomProperties>
})