import gsap from 'gsap';
import { MotionPathPlugin, CSSPlugin } from 'gsap/all';
import { createPinia } from 'pinia';
import { createApp, type IStore } from 'vue';
import { createI18n } from 'vue-i18n';
import type { NavigationGuardNext, RouteLocation } from 'vue-router';
import App from './App.vue';
import './less/index.less';
import router from './router';
import DataStore from './store/DataStore';
import StoreProxy from './store/StoreProxy';
import { storeAuth } from './store/auth/storeAuth';
import { storeGame } from './store/game/storeGame';
import { storeMain } from './store/main/storeMain';
import Config from './utils/Config';

const pinia = createPinia();

let lang: string = navigator.language || (<any>navigator)['userLanguage'];
lang = lang.substring(0, 2).toLowerCase();
const sLang = DataStore.get(DataStore.LANGUAGE);
if(sLang) lang = sLang;
const i18n = createI18n({
	locale:lang,
	fallbackLocale: 'en',
	warnHtmlInMessage:'off',
	silentFallbackWarn:!Config.instance.IS_PROD,
	silentTranslationWarn:!Config.instance.IS_PROD,
	// modifiers:{
	// 	strong:(str)=> "<strong>"+str+"</strong>",
	// }
});


gsap.registerPlugin(MotionPathPlugin, CSSPlugin);


//Load labels before everything else so they are available when
//initializing stores data
(async()=> {
	try {
		window.setInitMessage("chargement des labels");
		const labelsRes = await fetch("/labels.json");
		const labelsJSON = await labelsRes.json();
		for (const lang in labelsJSON) {
			i18n.global.setLocaleMessage(lang, labelsJSON[lang]);
		}
	}catch(error) {
		console.log(error);
		setTimeout(() => {
			storeMain().alert( "An error occured when loading labels :(" );
			storeMain().initComplete = true;
		}, 1000);
	}
	buildApp();
})();

function buildApp() {
	let isFirstRoute = true;
	/**
	 * Add route guards for login
	 */
	router.beforeEach(async (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) => {
		const sMain = StoreProxy.main;
	
		if (!sMain.initComplete) {
			try {
				await new Promise((resolve) => { sMain.startApp(true, resolve); });
			}catch(error) {
				console.log(error);
			}
		}

		// if(!isFirstRoute){
			const holder = document.getElementById("init_landscape")!;
			const behindAll = document.getElementById("init_landscape_behindAll");
			const background = document.getElementById("init_landscape_background");
			const foreground = document.getElementById("init_landscape_foreground");
			const logo = document.getElementById("init_landscape_logo");
			const aboveAll = document.getElementById("init_landscape_aboveAll");
			gsap.killTweensOf(behindAll);
			gsap.killTweensOf(background);
			gsap.killTweensOf(logo);
			gsap.killTweensOf(foreground);
			gsap.killTweensOf(aboveAll);
			gsap.killTweensOf(holder);
			if(to.name !== "home") {
				setTimeout(() => {
					gsap.to(behindAll, {opacity:0, duration:.25, ease:"sine.in"});
					gsap.to(background, {opacity:0, y:1000, duration:.5, ease:"sine.in"});
					gsap.to(logo, {opacity:0, y:-100, delay:.1, duration:.5, ease:"sine.in"});
					gsap.to(foreground, {y:1500, duration:1, ease:"sine.in"});
					gsap.to(aboveAll, {opacity:0, y:-100, duration:.5, ease:"sine.in"});
					gsap.to(holder, {opacity:0, delay:.75, duration:.25, onComplete:()=>{
						holder.style.display = "none";
					}});
				}, to.name == "play"? 350 : 0);
			}else if(to.name=="home") {
				holder.style.display = "block";
				gsap.set(behindAll, {clearProps:"all"});
				gsap.set(background, {clearProps:"all"});
				gsap.set(logo, {clearProps:"all"});
				gsap.set(foreground, {clearProps:"all"});
				gsap.set(aboveAll, {clearProps:"all"});
				gsap.set(holder, {clearProps:"all"});

				gsap.from(behindAll, {opacity:0, duration:.25, ease:"sine.out"});
				gsap.from(background, {opacity:0, y:1000, duration:.75, ease:"sine.out"});
				gsap.from(logo, {opacity:0, y:-100, delay:.1, duration:.5, ease:"sine.out"});
				gsap.from(foreground, {y:1500, duration:1, ease:"sine.out"});
				gsap.from(aboveAll, {opacity:0, y:-100, duration:.5, ease:"sine.out"});
				gsap.from(holder, {opacity:0, duration:.25});
			}
		// }
	
		isFirstRoute = false;
		next();
	});
	
	/**
	 * Include an asset from the asset folder
	 */
	const asset = (path:string):string => {
		return new URL(`/src_front/assets/${path}`, import.meta.url).href;
	}
	
	/**
	 * Opens up a confirm window so the user can confirm or cancel an action.
	 */
	const confirm = <T>(title: string,
		description?: string,
		data?: T,
		yesLabel?:string,
		noLabel?:string,
		STTOrigin?:boolean): Promise<T|undefined> => {
		return StoreProxy.main.confirm(title, description, data, yesLabel, noLabel, STTOrigin);
	}
	
	const app = createApp(App)
	.use(pinia);
	
	//Init stores before instanciating the router because the
	//router needs to access some stores
	StoreProxy.i18n = i18n.global;
	StoreProxy.asset = asset;
	StoreProxy.main = storeMain();
	StoreProxy.auth = storeAuth();
	StoreProxy.game = storeGame();
	StoreProxy.router = router;

	const storeAccess:IStore = {
		main: StoreProxy.main,
		auth: StoreProxy.auth,
		game: StoreProxy.game,
	};
	
	app.use(router)
	.use(i18n)
	.provide("$config", Config.instance)
	.provide("$image", asset)
	.provide("$store", storeAccess)
	.provide("$confirm", confirm)
	.directive('autofocus', {
		mounted(el:HTMLDivElement, binding:unknown) {
			if((binding as {[key:string]:boolean}).value !== false) {
				//Disabling scroll avoids breaking layout when opening
				//a ChannelNotifications content that has an autofocus element.
				//In such case, if the focus is given during the opening
				//transition, it completely breaks the chat layout, adding
				//lots of space under the chat and activities.
				//The "preventScroll" flag avoids this.
				el.focus({preventScroll:true});
			}
		}
	})
	.directive('click2Select', {
		mounted(el:HTMLElement, binding:unknown) {
			if((binding as {[key:string]:boolean}).value !== false) {
				el.style.cursor = "default";
				el.addEventListener("pointertap", ()=> {
					if(el.nodeName === "INPUT") {
						(el as HTMLInputElement).select();
					}else{
						el.ownerDocument?.getSelection()?.selectAllChildren(el);
					}
				});
			}
		}
	})
	app.config.globalProperties.$image = asset;
	app.config.globalProperties.$config = Config.instance;
	app.config.globalProperties.$confirm = confirm;
	app.config.globalProperties.$store = storeAccess;
	app.mount('#app');
}