import StoreProxy from "@/store/StoreProxy";
import FontFaceObserver from "fontfaceobserver";
/**
* Created : 10/01/2024 
*/
export default class FontsManager {

	private static _instance:FontsManager;
	
	constructor() {
	
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	static get instance():FontsManager {
		if(!FontsManager._instance) {
			FontsManager._instance = new FontsManager();
		}
		return FontsManager._instance;
	}
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	public async load():Promise<void> {
		for (const key in MTP_FONTS) {
			let font = new FontFaceObserver(MTP_FONTS[key as keyof typeof MTP_FONTS]);
			try {
				await font.load();
			}catch(error) {
				console.log(error);
				StoreProxy.main.alert("Something went wrong when loading fonts :(", true);
			}
		}
		for (const key in MTP_FONTS) {
			let font = new FontFaceObserver(MTP_FONTS[key as keyof typeof MTP_FONTS], {weight:"bold"});
			try {
				await font.load();
			}catch(error) {
				console.log(error);
				StoreProxy.main.alert("Something went wrong when loading fonts :(", true);
			}
		}
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
}

/**
 * The values must match the font names declared on {@link /src_front/assets/fonts/}
 */
export const MTP_FONTS = {
	AVENIR: "Avenir",
	// OPEN_DYSLEXIC: "OpenDyslexic",
}