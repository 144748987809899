import StoreProxy from '@/store/StoreProxy';
import * as PIXI from 'pixi.js';
import spritesheetJSON from "../assets/img/spritesheet.json";

/**
* Created : 10/01/2024 
*/
export default class TextureManager {

	private static _instance:TextureManager;

	private _spritesheet!:PIXI.Spritesheet;
	private _textStyle:PIXI.TextStyleOptions = {
		fontFamily: "Avenir-Bold",
		fontSize: 50,
		align:"left",
		letterSpacing:0,
		trim: true,
	};
	
	constructor() {
	
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	static get instance():TextureManager {
		if(!TextureManager._instance) {
			TextureManager._instance = new TextureManager();
		}
		return TextureManager._instance;
	}

	public get spritesheet() { return this._spritesheet; }

	public getTextTexture(text:string, color:string = "000000", size:number = 50) {
		return new PIXI.BitmapText({
			text:text.toUpperCase(), 
			style:{
				...this._textStyle,
				fill:color,
				fontSize:size,
			}
		});
	}
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	public async load():Promise<void> {
		await PIXI.Assets.load({
			alias:"motadexBackground",
			src:StoreProxy.asset("img/board.png"),
		});
		const tex = await PIXI.Assets.load(StoreProxy.asset("img/spritesheet.png"));
		this._spritesheet = new PIXI.Spritesheet(
			tex,
			spritesheetJSON
		);

		try {
			await this._spritesheet.parse();
		}catch(error) {
			StoreProxy.main.alert("Something went wrong when loading assets :(", true);
		}
	}

	public async initFonts():Promise<void> {
		// Load bitmap font
		await PIXI.Assets.load("/fonts/avenir/regular.xml");
		await PIXI.Assets.load("/fonts/avenir/bold.xml");
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
}

export type MTP_TEXTURE_IDS = keyof typeof spritesheetJSON.frames | "motadexBackground";

export function MTP_TEXTURE(id:MTP_TEXTURE_IDS) {
	return TextureManager.instance.spritesheet.textures[id] ?? PIXI.Assets.get(id);
}

export function MTP_LETTER(letter:string, color:string = "000000") {
	return TextureManager.instance.getTextTexture(letter.toLowerCase(), color);
}