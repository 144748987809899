import { reactive } from "vue";
import { MTP_TEXTURE } from './TextureManager';
import configsJSON from "../assets/configs.json";

/**
 * Created by Durss
 */
export default class Config {

	private static _instance:Config;

	/**
	 * Port for the twitchat's server services
	 */
	public SERVER_PORT = 3027;
	/**
	 * Is Twitchat running on a production server ?
	 */
	public IS_PROD:boolean = document.location.hostname != "localhost" && document.location.hostname != "127.0.0.1" && document.location.hostname != "192.168.1.10";
	/**
	 * Twitchat API path
	 */
	public TWITCH_API_PATH = "https://api.twitch.tv/helix/";
	/**
	 * Get cells size
	 */
	public get CELL_SIZE():number{ return MTP_TEXTURE("grid").height; }
	/**
	 * Get render scale
	 */
	public get RENDER_SCALE():number{ return window.devicePixelRatio > 1? .5 : 1 }

	/**
	 * Get external JSON configs
	 */
	public get jsonConf() { return configsJSON; }
	
	private _serverConfig!:ServerConfig;
	
	constructor() {
	
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	static get instance():Config {
		if(!Config._instance) {
			Config._instance = reactive(new Config()) as Config;
		}
		return Config._instance;
	}

	/**
	 * Path to server API
	 */
	public get API_PATH():string {
		if(!this.IS_PROD) {
			return document.location.protocol+"//"+document.location.hostname+":"+this.SERVER_PORT+"/api";
		}else{
			return document.location.protocol+"//"+document.location.hostname+"/api";
		}
	}

	/**
	 * Get if current instance is a beta instance
	 */
	public get BETA_MODE():boolean {
		return document.location.host.indexOf("beta") > -1 || document.location.host.indexOf("localhost") > -1;
	}

	/**
	 * Twitch client ID of Twitchat app
	 */
	public get TWITCH_CLIENT_ID():string { return this._serverConfig.twitch_client_id; }
	/**
	 * List of twitch scopes Twitchat can request
	 */
	public get TWITCH_APP_SCOPES():string[] { return this._serverConfig.twitch_scopes; }
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	public populateServerConfigs(data:ServerConfig):void {
		this._serverConfig = data;
	}

	public getParamByKey(key:string):unknown {
		return this[key as keyof typeof Config.instance];
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
}

export interface ServerConfig {
	twitch_client_id: string;
	twitch_scopes: string[];
}