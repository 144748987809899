<template>
	<div class="home">
		<div class="form">
			<MButton :to="{name:'play'}" :loading="loading" @click.native.capture.prevent="play()">Jouer</MButton>
			<MButton disabled :to="{name:'twitch'}">Connexion Twitch</MButton>
		</div>
	</div>
</template>

<script lang="ts">
import MButton from '@/components/MButton.vue';
import gsap from 'gsap';
import { Component, Vue, toNative } from 'vue-facing-decorator';

@Component({
	components:{
		MButton,
	},
	emits:[],
})
class Home extends Vue {

	public loading = false;

	public mounted():void {
		gsap.fromTo((this.$el as HTMLElement).querySelectorAll(".mbutton"), {opacity:0}, {opacity:1, ease:"sine.out", duration:.25, stagger:.15})
		gsap.fromTo((this.$el as HTMLElement).querySelectorAll(".mbutton"), {y:-40}, {y:0, ease:"elastic.out", duration:1.5, stagger:.15})
	}

	public async play():Promise<void> {
		this.loading = true;
		await this.$store.game.startLevel(0);
		this.$router.push({name:"play"});
	}

}
export default toNative(Home);
</script>

<style scoped lang="less">
.home{
	width: 100vw;
	height: 100vh;
	z-index: 3;
	.form {
		max-width: 400px;
		position: absolute;
		top: 60%;
		left: 48%;
		transform: translate(-50%, -50%);
		gap: 1em;
		display: flex;
		flex-direction: column;

		.mbutton {
			min-width: 250px;
			align-self: center;
			box-shadow: 3px 5px 0 rgba(0, 0, 0, .5);
		}
	}
}
</style>