import Home from '@/views/Home.vue'
import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

const Game = () => import('@/views/Game.vue');
const GameLightVue = () => import('@/views/GameLight.vue');

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
		}
	},
	{
		path: '/play',
		name: 'play',
		component: Game,
		meta: {
		}
	},
	{
		path: '/twitch',
		name: 'twitch',
		component: Game,
		meta: {
		}
	},
	{
		path: '/test',
		name: 'test',
		component: GameLightVue,
		meta: {
		}
	},
    {
      path: "/:path(.*)*",
      redirect: ()=> {
		return {name:'home'}
	  }
    }
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router