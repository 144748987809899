import type { IGameState } from '@/store/StoreProxy';
import { MotaponTypes } from '@/types/MotaponTypes';
import { AStarFinder } from 'astar-typescript';
import Utils from './Utils';
import Config from './Config';
import type { MTP_TEXTURE_IDS } from './TextureManager';
import TextureManager from './TextureManager';

/**
* Created : 14/02/2024 
*/
export default class LevelManager {

	private static _instance:LevelManager;
	private _aStarInstance!:AStarFinder;
	private _aStarMap:number[][] = [];
	private _canvasDebug!:HTMLCanvasElement;
	
	constructor() {
	
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	static get instance():LevelManager {

		// const keys = Object.keys(spritesheetData.frames);
		// console.log(keys.filter( k => k.indexOf("objects/") > -1).map(v=> {return {proba:1, sprite:v}}));

		if(!LevelManager._instance) {
			LevelManager._instance = new LevelManager();
			LevelManager._instance.initialize();
		}
		return LevelManager._instance;
	}
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	/**
	 * Builds a level at the given sizes
	 * @param levelIndex  
	 * @param levelH 
	 * @param levelW 
	 * @returns 
	 */
	public async buildLevel(levelIndex:number, levelW:number, levelH:number):Promise<IGameState["map"]> {
		const map:IGameState["map"] = [];
		this._aStarMap = [];
		for (let y = 0; y < levelH; y++) {
			const row:IGameState["map"][number] = [];
			const astarRow1:number[] = [];
			const astarRow2:number[] = [];
			const astarRow3:number[] = [];
			for (let x = 0; x < levelW; x++) {
				row.push({sprite:"empty", directions:0b1111});
				astarRow1.push(0,0,0);
				astarRow2.push(0,0,0);
				astarRow3.push(0,0,0);
			}

			map.push(row);
			this._aStarMap.push(astarRow1);
			this._aStarMap.push(astarRow2);
			this._aStarMap.push(astarRow3);
		}

		const startY = Math.floor(map.length/2);
		const startRow = map[startY];
		startRow[0].sprite = "start";
		startRow[1].sprite = "start";
		startRow[2].sprite = "bridge/start";
		startRow[0].directions = 
		startRow[1].directions = MotaponTypes.CellTypeToDirections("start")
		startRow[2].directions = MotaponTypes.CellTypeToDirections("bridge/start");
		startRow[startRow.length-1].sprite = "bridge/bridge";

		//Fish blocking word use test (FIXED)
			// map[3][3] = {sprite:"rocks/small_0"};
			// map[0][4] = {sprite:"rocks/small_0"};
			// map[1][6] = {sprite:"fish"};
			// map[2][6] = {sprite:"rocks/small_0"};
			// map[3][7] = {sprite:"rocks/small_0"};
			// map[4][5] = {sprite:"rocks/big_2"};
			// map[1][5] = {sprite:"letter", letterData:{id:"0", disabled:false, letter:"M", error:false, selected:false}, drown:false};
			// map[2][5] = {sprite:"letter", letterData:{id:"1", disabled:false, letter:"A", error:false, selected:false}, drown:false};
			// map[3][5] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};

		//Bakward walk test (FIXED)
			// map[5][3] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[5][4] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[5][5] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[5][6] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[5][7] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[6][7] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[7][7] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[7][6] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[7][5] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[7][4] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[7][3] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"I", error:false, selected:false}, drown:false};
			// map[7][2] = {sprite:"bridge/bridge", object:'objects/ane', drown:false};
			// map[6][8] = {sprite:"bridge/bridge", object:'objects/abeille', drown:false};
			// map[8][7] = {sprite:"bridge/bridge", drown:false};
			// map[7][13] = {sprite:"bridge/bridge", drown:false};
			
		//Impossible to place word "PALME" near bottom of board (FIXED)
			// map[9][12] = {sprite:"letter", letterData:{id:"0", disabled:false, letter:"M", error:false, selected:false}, drown:false};
			// map[9][13] = {sprite:"letter", letterData:{id:"1", disabled:false, letter:"E", error:false, selected:false}, drown:false};
			// map[9][14] = {sprite:"letter", letterData:{id:"2", disabled:false, letter:"R", error:false, selected:false}, drown:false};
			// map[9][15] = {sprite:"letter", letterData:{id:"3", disabled:false, letter:"O", error:false, selected:false}, drown:false};
			// map[9][16] = {sprite:"letter", letterData:{id:"4", disabled:false, letter:"U", error:false, selected:false}, drown:false};
			// map[8][16] = {sprite:"bridge/bridge", drown:false};

		this.placeRocks(levelIndex, map);
		this.placeSlots(levelIndex, map);
		this.placeFishes(levelIndex, map);

		this.computeAstarFromMap(map, true);
		
		let path:number[][] = [];
		try {
			path = this._aStarInstance.findPath({x:1, y:(startY*3)+1}, {x:(startRow.length-1)*3+1, y:startY*3+1});
		}catch(error) {
		}
		if(path.length == 0) {
			await Utils.promisedTimeout(100);
			return await this.buildLevel(levelIndex, levelW, levelH);
		}

		this.updateMap(map, true);
		setTimeout(()=>{
			this.updateMap(map);
		}, 1000);

		// Fill map with letter for perf tests
		/*
		let count = 0;
		for (let y = 0; y < levelH; y++) {
			for (let x = 0; x < levelW; x++) {
				if(map[y][x].sprite == "empty") {
					count ++;
					map[y][x] = {sprite:"letter", letterData:{disabled:false, error:false, id:Math.random().toString(), letter:"A, selected:false}", drown:false};
				}
			}
		}
		console.log(count)
		//*/
		
		return map;
	}

	public updateMap(map:IGameState["map"], initMode = false, disableDrownTiles:boolean = false):void {
		this.computeAstarFromMap(map, initMode, disableDrownTiles);

		/*
		if(!this._canvasDebug) {
			this._canvasDebug = document.createElement("canvas");
		}
		const ctx = this._canvasDebug.getContext("2d")!;
		ctx.clearRect(0,0,this._canvasDebug.width,this._canvasDebug.height);
		this._canvasDebug.width = this._aStarMap[0].length;
		this._canvasDebug.height = this._aStarMap.length;
		for (let y = 0; y < this._aStarMap.length; y++) {
			const row = this._aStarMap[y];
			for (let x = 0; x < row.length; x++) {
				const cell = row[x];
				ctx.fillStyle = "black"; //x%3 == 1 && y%3 == 1? "red" : "black";
				if(cell === 1)ctx.fillRect(x, y, 1, 1);
			}
		}

		//Render solution path
		ctx.fillStyle = "green";
		for (let i = 0; i < path.length; i++) {
			const [x, y] = path[i];
			ctx.fillRect(x, y, 1, 1);
		}
		this._canvasDebug.style.position = "fixed";
		this._canvasDebug.style.left = "0px";
		this._canvasDebug.style.top = "0px";
		this._canvasDebug.style.transform = "scale(10) translate(50%, 50%)";
		this._canvasDebug.style.imageRendering = "pixelated";
		this._canvasDebug.style.zIndex = "9999";
		this._canvasDebug.style.opacity = ".5";
		this._canvasDebug.style.pointerEvents = "none";
		this._canvasDebug.style.width = this._aStarMap[0].length+"px";
		this._canvasDebug.style.height = this._aStarMap.length+"px";
		document.body.appendChild(this._canvasDebug);
		//*/
	}

	/**
	 * Get path from start to end point
	 * @param currentX 
	 * @param currentY 
	 * @param targets 
	 * @param map 
	 * @returns 
	 */
	public getPath(start:{x:number, y:number}, end:{x:number, y:number}):number[][] {
		try {
			const path = this._aStarInstance.findPath({x:start.x * 3 + 1, y:start.y * 3 + 1}, {x:end.x*3 + 1, y:end.y*3 + 1});
			if(path && path.length > 0) {
				return path.filter(v => (v[0]-1)%3 == 0 && (v[1]-1)%3 == 0)
							.map(v=> {
								return [Math.floor(v[0]/3), Math.floor(v[1]/3)]
							});
			}
		}catch(error) {
		}
		return [];
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
	private initialize():void {
	}

	/**
	 * Builds up A* matrix from given map
	 * @param map 
	 * @param initMode if true, water is considered as walkable like bridges. If false, only bridges are considered walkable
	 * @returns 
	 */
	private computeAstarFromMap(map:IGameState["map"], initMode:boolean = false, disableDrownTiles:boolean = false):void {
		for (let y = 0; y < map.length; y++) {
			for (let x = 0; x < map[y].length; x++) {
				const cell = map[y][x];
				if(initMode) {
					let empty = cell.sprite === "empty" || cell.sprite === "bridge/bridge" || cell.sprite === "bridge/start" || cell.sprite === "start";
					const dir = cell.directions || MotaponTypes.CellTypeToDirections(cell.sprite);
					this.setAstarCell(x, y, empty? 0 : 1, dir);
				}else {
					let bridge = cell.sprite === "bridge/bridge" || cell.sprite === "bridge/start" || cell.sprite === "start" || cell.sprite === "letter";
					const dir = cell.directions || MotaponTypes.CellTypeToDirections(cell.sprite);
					//Do not flag drown cells as walkable
					if(disableDrownTiles && (cell.sprite == "letter" || cell.sprite == "bridge/bridge") && cell.drown) bridge = false;
					this.setAstarCell(x, y, bridge? 0 : 1, bridge? dir : 0);
				}
			}
		}
		this._aStarInstance = new AStarFinder({grid:{matrix:this._aStarMap}, diagonalAllowed:false, includeEndNode:false, includeStartNode:false, allowPathAsCloseAsPossible:false});
	}

	/**
	 * Places random slots
	 * @param map 
	 */
	private placeSlots(levelIndex:number, map:IGameState["map"]):void {
		let count = 4 - levelIndex*4;
		do {
			let x = Math.floor(Math.random() * map[0].length * .5 + map[0].length * .25);
			let y = Math.floor(Math.random() * map.length * .25 + (Math.random()>.5? 0: map.length * .75));
			//Make sure nothing is on the renadomly picked cell
			if(map[y][x]?.sprite != "empty") continue;
			//Make sure the cell is accessible
			if(map[y][x+1]?.sprite == "bridge/bridge") continue;
			if(map[y][x-1]?.sprite == "bridge/bridge") continue;
			if(map[y+1] && map[y+1][x]?.sprite == "bridge/bridge") continue;
			if(map[y-1] && map[y-1][x]?.sprite == "bridge/bridge") continue;

			if(Math.random() > .5) {
				//Empty slot
				map[y][x] = {sprite:"bridge/bridge", directions:MotaponTypes.CellTypeToDirections("bridge/bridge"), drown:false };

			// }else if(Math.random() > .5) {
			// 	//Select a random chest
			// 	const chests:{proba:number, value:MotaponTypes.MapInbetweeSlot["chest"]}[] = [
			// 		{ proba:100, value: "chests/wood" },
			// 		{ proba:50, value: "chests/normal" },
			// 		{ proba:10, value: "chests/bomb" },
			// 	];
			// 	map[y][x] = {sprite:"bridge/bridge", directions:MotaponTypes.CellTypeToDirections("bridge/bridge"), chest:Utils.pickRandProb(chests) };

			}else if(Math.random() < .05){
				//Select a random object
				type validKeys = MotaponTypes.FilterKeys<keyof typeof Config.instance.jsonConf.probabilities, "objects">;
				const keys = Object.keys(Config.instance.jsonConf.probabilities).filter(key => key.indexOf("objects/") > -1) as validKeys[];
				
				let objects:{proba:number, value:MotaponTypes.MapInbetweeSlot["object"]}[] = [];
				for (let i = 0; i < keys.length; i++) {
					const k = keys[i];
					objects.push({ proba:Config.instance.jsonConf.probabilities[k] || 1, value: k });
				}

				map[y][x] = {sprite:"bridge/bridge", directions:MotaponTypes.CellTypeToDirections("bridge/bridge"), object:Utils.pickRandProb(objects), drown:false };
			}
			count --;	
		}while(count > 0)
	}

	/**
	 * Places random rocks
	 * @param map 
	 */
	private placeRocks(levelIndex:number, map:IGameState["map"]):void {
		//Add small rocks
		let added = 0;
		let attempts = 0;
		do {
			attempts ++;
			const x = Math.floor(Math.random() * map[0].length);
			const y = Math.floor(Math.random() * map.length);
			if(map[y][x].sprite != "empty") continue;

			const rocks:MotaponTypes.FilterKeys<MTP_TEXTURE_IDS, "rocks">[] = ["rocks/small_0", "rocks/small_1", "rocks/small_2", "rocks/small_3"];
			if(Math.random() < Config.instance.jsonConf.probabilities['rocks/small_marked']) rocks.push("rocks/small_marked");
			type t = (typeof rocks)[number];
			let rock = Utils.pickRand<t>(rocks);
			map[y][x] = {sprite:rock, flip:Math.random()>.5, directions:MotaponTypes.CellTypeToDirections(rock)};
			added++;
		}while((Math.random() < .7 || added < 10) && attempts < 200)
		
		//Add big rocks
		added = 0
		attempts = 0
		do {
			attempts ++;
			const x = Math.floor(Math.random() * (map[0].length-1));
			const y = Math.floor(Math.random() * (map.length-1));
			if(map[y][x].sprite != "empty") continue;
			if(map[y][x+1].sprite != "empty") continue;
			if(map[y+1][x].sprite != "empty") continue;
			if(map[y+1][x+1].sprite != "empty") continue;

			const rocks:MotaponTypes.FilterKeys<MTP_TEXTURE_IDS, "rocks">[] = ["rocks/big_0", "rocks/big_1", "rocks/big_2", "rocks/big_3"];
			type t = (typeof rocks)[number];
			let rock = Utils.pickRand<t>(rocks);
			map[y][x] = {sprite:rock, flip:Math.random()>.5, directions:MotaponTypes.CellTypeToDirections(rock)};
			map[y][x+1] = {sprite:"lock", directions:MotaponTypes.CellTypeToDirections("lock")};
			map[y+1][x] = {sprite:"lock", directions:MotaponTypes.CellTypeToDirections("lock")};
			map[y+1][x+1] = {sprite:"lock", directions:MotaponTypes.CellTypeToDirections("lock")};
			added++;
		}while((Math.random() < .5 || added < 1) && attempts < 200);
	}

	/**
	 * Places random fishes
	 * @param map 
	 */
	private placeFishes(levelIndex:number, map:IGameState["map"]):void {
		let count = 0;
		for (let i = 0; i < 5; i++) {
			if(Math.random() > .5) count ++;
			else break;
		}
		let added = 0;
		let attempts = 0;
		do {
			attempts ++;
			const x = Math.floor(Math.random() * map[0].length);
			const y = Math.floor(Math.random() * map.length);
			if(map[y][x].sprite != "empty") continue;

			const data:MotaponTypes.MapFish = {sprite:"fish"};
			map[y][x] = data;
			added++;
		}while((added < count) && attempts < 200);
	}

	/**
	 * Define an A* celle and blocks sides as requested
	 * @param x 
	 * @param y 
	 * @param value 
	 * @param top 
	 * @param right 
	 * @param bottom 
	 * @param left 
	 */
	private setAstarCell(x:number, y:number, value:number, directions:MotaponTypes.DirectionBitFlag):void {
		this._aStarMap[y*3+1][x*3+1] = value;
		
		//Lock corners
		this._aStarMap[y*3][x*3] =
		this._aStarMap[y*3][x*3+2] =
		this._aStarMap[y*3+2][x*3] =
		this._aStarMap[y*3+2][x*3+2] = 1;

		//Set side pathways
		this._aStarMap[y*3][x*3+1] = ((directions >> 3) & 0b1) == 1? 0 : 1;
		this._aStarMap[y*3+1][x*3+2] = ((directions >> 2) & 0b1) == 1? 0 : 1;
		this._aStarMap[y*3+2][x*3+1] = ((directions >> 1) & 0b1) == 1? 0 : 1;
		this._aStarMap[y*3+1][x*3] = ((directions >> 0) & 0b1) == 1? 0 : 1;
	}
}