import DataStore from "@/store/DataStore";
import type { MotaponTypes } from "@/types/MotaponTypes";
import type { MTP_TEXTURE_IDS } from "@/utils/TextureManager";

/**
* Created : 07/04/2024 
*/
export default class Database {

	private static _instance:Database;

	private _fishes:{id:MotaponTypes.FilterKeys<MTP_TEXTURE_IDS, "fish">, count:number}[] = [];
	
	constructor() {
	
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	static get instance():Database {
		if(!Database._instance) {
			Database._instance = new Database();
			Database._instance.initialize();
		}
		return Database._instance;
	}
	
	public get capturedFishes() { return this._fishes; }
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	/**
	 * Flags a fish as captured
	 * @param id 
	 */
	public captureFish(id:typeof this._fishes[0]["id"]) {
		let item = this.capturedFishes.find(entry => entry.id === id);
		if(!item) {
			item = {id, count:0};
			this._fishes.push(item);
		}
		item.count ++;
		DataStore.set(DataStore.FISHES, this._fishes);
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
	private initialize():void {
		this._fishes = JSON.parse(DataStore.get(DataStore.FISHES)) || [];
	}
}